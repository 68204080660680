import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { NetworkController } from "../../../../utils";
import { PropertyCard } from "../../../../styles";
import {
  DropDownContainer,
  Spacing,
  DropDown,
  FormRow,
  InlineText,
  Text,
  FormColumn,
} from "../../../../commonalities";

const CNMSettingsDropDown = ({ shrink, job }) => {
  const [settings, setSettings] = useState(null);
  const [config, setConfig] = useState(null);
  const [subToolWidths, setSubToolWidths] = useState(null);
  const { t } = useTranslation();
  const [calibration, setCalibration] = useState({});
  const [calibrationSelector, setCalibrationSelector] = useState("Fixture");
  const [calibrationData, setCalibrationData] = useState({});

  useEffect(() => {
    NetworkController.cells.getSettingsData({ id: job.Payload.Id }, (res) => {
      if (res.data.settings && res.data.config) {
        var settings = res.data.settings.Items[0].Payload.Settings;
        setSettings(settings);
        var config = res.data.config.Items[0].Payload.Config;
        if (typeof config === "string") {
          config = JSON.parse(config);
        }
        setConfig(config);
        var fixtureCalib = null;

        var subToolWidths = [];
        config &&
          config.tools.tools[0].sub_tools &&
          config.tools.tools[0].sub_tools.map((element) => {
            return element.properties.map((prop) => {
              if (prop.key === "width") {
                subToolWidths.push({
                  name: element.sub_tool_name,
                  value: prop.value,
                });
              }
            });
          });
        setSubToolWidths(subToolWidths);

        var calib = {};
        var calibData = {};
        //The if block should be removed once all the cells are migrated to a CNM version > 4.0.0
        if (settings.SelectedToolName) {
          config.tools.tools.forEach((element) => {
            if (element.tool_name === settings.SelectedToolName) {
              calib["Tool"] = t("cell-settingsTool");
              calibData["Tool"] = [];
              element.sub_tools.forEach((subTool) => {
                // Tool transform position is stored in m in database
                // We convert it to mm to be in sync with application and other settings
                var toolTransform = {
                  orientation: subTool.calib_transform.orientation,
                  position: {
                    x: subTool.calib_transform.position.x * 1000,
                    y: subTool.calib_transform.position.y * 1000,
                    z: subTool.calib_transform.position.z * 1000,
                  },
                };
                if (subTool.properties.length > 0) {
                  calibData["Tool"].push({
                    name: subTool.sub_tool_name,
                    value: toolTransform,
                  });
                }
              });
            }
          });
        } else {
          config.tools.tools.forEach((subTool) => {
            if (subTool.tool_id === settings.SelectedToolId) {
              calib["Tool"] = t("cell-settingsTool");
              calibData["Tool"] = []; // Tool transform position is stored in m in database
              // We convert it to mm to be in sync with application and other settings
              var toolTransform = {
                orientation: subTool.calib_transform.orientation,
                position: {
                  x: subTool.calib_transform.position.x * 1000,
                  y: subTool.calib_transform.position.y * 1000,
                  z: subTool.calib_transform.position.z * 1000,
                },
              };
              calibData["Tool"].push({
                name: settings.SelectedToolId,
                value: toolTransform,
              });
            }
          });
        }
        config.fixtures.fixtures.forEach((element) => {
          if (parseInt(element.wobj_count) === parseInt(settings.Fixture)) {
            fixtureCalib = element;
            calib["Fixture"] = t("cell-settingsFixture");
            calibData["Fixture"] = [];
            calibData["Fixture"].push({
              name: "Fixture",
              value: element.base_transform,
            });
          }
        });

        calibData.Fixture.size = fixtureCalib.wobj_data[0].size;
        for (var i = 0; i < fixtureCalib.wobj_data.length; i++) {
          calib[i] = t("cell-fixtureSlot") + " " + (i + 1);
          calibData[i] = [];
          var slotCalibData = fixtureCalib.wobj_data[i].transform;

          let key = "F" + settings.Fixture + "_S" + (i + 1) + "_";
          let calibSettings = {};
          calibSettings["Tile length"] = settings[key + "TileX"];
          calibSettings["Tile width"] = settings[key + "TileY"];
          calibSettings["X"] = settings[key + "XCutCalib"];
          calibSettings["Y"] = settings[key + "YCutCalib"];
          calibSettings["Cutting depth"] = settings[key + "CutDepthCalib"];
          slotCalibData.calibSettings = calibSettings;

          let calibMeasurements = {};
          calibMeasurements["X1"] = settings[key + "X1Calib"];
          calibMeasurements["X2"] = settings[key + "X2Calib"];
          calibMeasurements["Y1"] = settings[key + "Y1Calib"];
          calibMeasurements["Y2"] = settings[key + "Y2Calib"];
          calibMeasurements["L"] = settings[key + "LCalib"];
          calibMeasurements["R"] = settings[key + "RCalib"];
          calibMeasurements["T"] = settings[key + "TCalib"];
          calibMeasurements["B"] = settings[key + "BCalib"];
          slotCalibData.calibMeasurements = calibMeasurements;

          calibData[i].push({
            name: key,
            value: slotCalibData,
          });
        }
        setCalibration(calib);
        setCalibrationData(calibData);
      } else {
        setSettings(res.data.Items[0].Payload.Settings);
      }
    });
  }, []);

  return (
    <Spacing mTopvw={"0.83"}>
      <DropDownContainer width={"100%"} height={"fit-content"} shrink={shrink}>
        {settings ? (
          <Spacing
            mTopvw={"1.25"}
            mRightvw={"1.25"}
            mLeftvw={"2.5"}
            mBottomvw={"2.5"}
          >
            <FormRow height="100%">
              <FormColumn
                height="fit-content"
                alignItems="stretch"
                width={"21.705%"}
              >
                <Spacing mTopvh="1.20" />
                <FormRow height="100%">
                  <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                    {t("cell-settingsFixture")}
                  </InlineText>
                </FormRow>
                <PropertyCard
                  name={"cell-settingsFixture"}
                  value={
                    settings.Fixture
                      ? settings.Fixture === "1"
                        ? t("cell-singleSlot")
                        : settings.Fixture + " " + t("cell-fixtureSlot")
                      : "-"
                  }
                />
                <Spacing mTopvh="2.96" />
                <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                  {t("cell-settingsTool")}
                </InlineText>
                {/* The conditional statement should be removed once all the cells are migrated
                to a CNM version > 4.0.0 */}
                <PropertyCard
                  name={"cell-settingsSelectedTool"}
                  value={
                    settings.SelectedToolName
                      ? settings.SelectedToolName
                      : settings.SelectedToolId
                      ? settings.SelectedToolId
                      : "-"
                  }
                />
                {subToolWidths && subToolWidths.length > 0 ? (
                  subToolWidths.map((element, i) => {
                    return (
                      <div key={i}>
                        <PropertyCard
                          name={
                            subToolWidths.length > 1
                              ? element.name +
                                " " +
                                t("cell-width").toLocaleLowerCase()
                              : t("cell-settingsToolWidth")
                          }
                          value={element.value + " mm"}
                        />
                      </div>
                    );
                  })
                ) : (
                  <>
                    <PropertyCard
                      name={"cell-settingsToolWidth"}
                      value={
                        settings.SelectedToolWidth
                          ? settings.SelectedToolWidth + " mm"
                          : "-"
                      }
                    />
                  </>
                )}
                <Spacing mTopvh="2.96" />
                <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                  {t("cell-settingsRubberMat")}
                </InlineText>
                <PropertyCard
                  name={"cell-settingsAdvancedRubber"}
                  value={settings.MatSelector === "1" ? "On" : "Off"}
                />
                {settings.MatSelector === "1" && (
                  <PropertyCard
                    name={t("cell-settingsAdvancedHeight")}
                    value={
                      settings.MatHeight ? settings.MatHeight + " mm" : "-"
                    }
                  />
                )}
              </FormColumn>

              <FormColumn
                height="fit-content"
                alignItems="stretch"
                width={"21.705%"}
              >
                <Spacing mTopvh="1.20" />
                <FormRow height="100%">
                  <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                    {t("cell-settingsDepth")}
                  </InlineText>
                </FormRow>
                <PropertyCard
                  name={"cell-settingsDepthCut"}
                  value={settings.CutDepth ? settings.CutDepth + " mm" : "-"}
                />
                <PropertyCard
                  name={"cell-settingsDepthSteps"}
                  value={settings.CutSteps}
                />
                {config && config.tools.tools[0].sub_tools ? (
                  config.tools.tools[0].sub_tools.map((element) => {
                    return element.properties.map((prop, i) => {
                      return (
                        prop.key === "wear" && (
                          <div key={i}>
                            <PropertyCard
                              name={"cell-settingsDepthWear"}
                              value={prop.value + " mm"}
                            />
                          </div>
                        )
                      );
                    });
                  })
                ) : (
                  <>
                    <PropertyCard
                      name={"cell-settingsDepthWear"}
                      value={
                        settings.SelectedToolWearCorrection
                          ? settings.SelectedToolWearCorrection + " mm"
                          : "-"
                      }
                    />
                  </>
                )}
                <Spacing mTopvh="2.96" />
                <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                  {t("cell-settingsSpeed")}
                </InlineText>
                <PropertyCard
                  name={"cell-settingsSpeedCut"}
                  value={settings.CutSpeed ? settings.CutSpeed + " mm/s" : "-"}
                />
                <PropertyCard
                  name={"cell-settingsSpeedDown"}
                  value={
                    settings.DownSpeed ? settings.DownSpeed + " mm/s" : "-"
                  }
                />
                <PropertyCard
                  name={"cell-settingsSpeedUp"}
                  value={settings.UpSpeed ? settings.UpSpeed + " mm/s" : "-"}
                />
                <PropertyCard
                  name={"cell-settingsSpeedLead"}
                  value={settings.LeadSpeed + " mm/s"}
                />
                <PropertyCard
                  name={"cell-settingsDownSpeedReduction"}
                  value={
                    settings.DownSpeedReduction
                      ? settings.DownSpeedReduction + " %"
                      : "-"
                  }
                />
              </FormColumn>

              <FormColumn
                height="fit-content"
                alignItems="stretch"
                width={"46.51%"}
              >
                <FormRow height="100%">
                  <Spacing mTopvh="1.02">
                    <InlineText color="black" fontSize={"xs"} font={"semibold"}>
                      {t("cell-calibration")}
                    </InlineText>
                  </Spacing>
                  {Object.keys(calibration).length > 0 && (
                    <Spacing
                      mTop={"auto"}
                      mBottom={"auto"}
                      mLeft={"auto"}
                      setFitContentHeight={true}
                      setFitContent={true}
                    >
                      <DropDown
                        scrollToView={false}
                        fontSize={"xs"}
                        font={"light"}
                        width={"8.67"}
                        borderRadius={"xxs"}
                        boxTopMargin={"35px"}
                        optionList={calibration}
                        onChange={(value) => setCalibrationSelector(value)}
                        value={calibrationSelector}
                      />
                    </Spacing>
                  )}
                </FormRow>
                {Object.keys(calibration).length === 0 && (
                  <>
                    <Spacing mTopvh="1.20" />
                    <InlineText color="black" fontSize={"xs"}>
                      {t("fms-dataUnavailable")}
                    </InlineText>
                  </>
                )}
                {calibrationData[calibrationSelector] &&
                  calibrationData[calibrationSelector].map((element, i) => {
                    return (
                      <div key={i}>
                        {calibrationData[calibrationSelector].length > 1 && (
                          <Spacing mTopvh="1.02">
                            <InlineText
                              color="black"
                              fontSize={"xxs"}
                              font={"semibold"}
                            >
                              {element.name}
                            </InlineText>
                          </Spacing>
                        )}
                        <Spacing mTopvh={"1.48"} />
                        <InlineText color="black" fontSize={"xxxs"}>
                          {t("cell-settingsToolPosition").toUpperCase()}
                        </InlineText>
                        <Spacing mTopvh="1.20" />
                        <FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                X
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.position.x * 1e6) /
                                  1e6 +
                                  " mm"}
                              </Text>
                            </FormColumn>
                          </FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                Y
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.position.y * 1e6) /
                                  1e6 +
                                  " mm"}
                              </Text>
                            </FormColumn>
                          </FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                Z
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.position.z * 1e6) /
                                  1e6 +
                                  " mm"}
                              </Text>
                            </FormColumn>
                          </FormRow>
                        </FormRow>
                        <Spacing mTopvh={"1.48"} />
                        <InlineText color="black" fontSize={"xxxs"}>
                          {t("cell-settingsToolOrientation").toUpperCase()}
                        </InlineText>
                        <Spacing mTopvh="1.20" />
                        <FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                X
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.orientation.x * 1e6) /
                                  1e6}
                              </Text>
                            </FormColumn>
                          </FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                Y
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.orientation.y * 1e6) /
                                  1e6}
                              </Text>
                            </FormColumn>
                          </FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                Z
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.orientation.z * 1e6) /
                                  1e6}
                              </Text>
                            </FormColumn>
                          </FormRow>
                        </FormRow>
                        <FormRow>
                          <FormRow height="100%" width={"29.68%"}>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"16.846%"}
                            >
                              <Text
                                height="fit-content"
                                fontSize="xs"
                                color={"textDarkGrey"}
                              >
                                W
                              </Text>
                            </FormColumn>
                            <FormColumn
                              height="fit-content"
                              alignItems="stretch"
                              width={"83.154%"}
                            >
                              <Text
                                height="fit-content"
                                textAlign="right"
                                fontSize="xs"
                              >
                                {Math.round(element.value.orientation.w * 1e6) /
                                  1e6}
                              </Text>
                            </FormColumn>
                          </FormRow>
                        </FormRow>
                      </div>
                    );
                  })}
                {calibrationData[calibrationSelector] &&
                  calibrationData[calibrationSelector][0].value.size && (
                    <>
                      <Spacing mTopvh={"1.48"} />
                      <InlineText color="black" fontSize={"xxxs"}>
                        {t("cell-settingsSlotSize").toUpperCase()}
                      </InlineText>
                      <Spacing mTopvh="1.20" />
                      <FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-lengthShort")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .size[0] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-widthShort")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .size[1] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-heightShort")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .size[2] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                    </>
                  )}
                {calibrationData[calibrationSelector] &&
                  calibrationData[calibrationSelector][0].value
                    .calibSettings && (
                    <>
                      <Spacing mTopvh={"1.48"} />
                      <InlineText color="black" fontSize={"xxxs"}>
                        {t("cell-settings").toUpperCase()}
                      </InlineText>
                      <Spacing mTopvh="1.20" />
                      <FormRow>
                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-settingsTileLength")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["Tile length"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-settingsTileWidth")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["Tile width"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                      <FormRow>
                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              X
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["X"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              Y
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"49.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["Y"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                      <FormRow>
                        <FormRow height="100%" width={"44.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"59.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              {t("cell-settingsCuttingDepth")}
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"39.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibSettings["Cutting depth"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                    </>
                  )}
                {calibrationData[calibrationSelector] &&
                  calibrationData[calibrationSelector][0].value
                    .calibMeasurements && (
                    <>
                      <Spacing mTopvh={"1.48"} />
                      <InlineText color="black" fontSize={"xxxs"}>
                        {t("cell-measurements").toUpperCase()}
                      </InlineText>
                      <Spacing mTopvh="1.20" />
                      <FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              X1
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["X1"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              X2
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["X2"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>

                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"33.69%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              Y1
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"66.31%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["Y1"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                      <FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              Y2
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["Y2"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              L
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["L"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              R
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["R"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                      </FormRow>
                      <FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              T
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["T"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                        <FormRow height="100%" width={"29.68%"}>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"16.846%"}
                          >
                            <Text
                              height="fit-content"
                              fontSize="xs"
                              color={"textDarkGrey"}
                            >
                              B
                            </Text>
                          </FormColumn>
                          <FormColumn
                            height="fit-content"
                            alignItems="stretch"
                            width={"83.154%"}
                          >
                            <Text
                              height="fit-content"
                              textAlign="right"
                              fontSize="xs"
                            >
                              {calibrationData[calibrationSelector][0].value
                                .calibMeasurements["B"] + " mm"}
                            </Text>
                          </FormColumn>
                        </FormRow>
                        <FormRow height="100%" width={"29.68%"}></FormRow>
                      </FormRow>
                    </>
                  )}
              </FormColumn>
            </FormRow>
          </Spacing>
        ) : (
          <Spacing
            mTopvw={"1.25"}
            mRightvw={"1.25"}
            mLeftvw={"2.5"}
            mBottomvw={"2.5"}
            height="289px"
          >
            <InlineText color="black">{t("cell-loadingSettings")}</InlineText>
          </Spacing>
        )}
      </DropDownContainer>
    </Spacing>
  );
};

export default CNMSettingsDropDown;
