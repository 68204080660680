import React from "react";
import { useStore } from "../store";
import * as Routes from "../routes/Routes";
import {
  EditIcon,
  InlineText,
  DetailsContainer,
  Spacing,
  Pill,
  ShadowDiv,
  Row,
  Img,
  FormColumn,
  IconHover,
  Tooltip,
} from "../commonalities";
import { useHistory } from "react-router-dom";
import image from "../images/Cell.png";


const CellCard = ({
  cellData,
  location,
  status,
  showEditCellPopUp,
  search,
}) => {
  const history = useHistory();
  const [{ auth }] = useStore();
  const el = React.useRef(undefined);
  const statusRef = React.useRef(undefined);

  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span style={{ fontFamily: "inherit" }}>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    backgroundColor: "#000",
                    color: "#fff",
                    fontFamily: "inherit",
                  }
                : { fontFamily: "inherit" }
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };
  return (
    <Spacing
      setFlex={true}
      minWidthvw="22.09"
      widthvw="22.615"
      heightvh="23.66"
      minHeight="fit-content"
    >
      <ShadowDiv
        borderRadius="sm"
        width={"20.01"}
        minWidth={"14"}
        vPadding={"1.84"}
        hPadding={"1.04"}
        onClick={() =>
          history.push(Routes.CELL_DETAILS.replace(":CellID", cellData.CellID))
        }
      >
        <Spacing height="90%" width="50%" margin="auto" textAlign="center">
          <Img height="90%" width="90%" src={image} />
        </Spacing>
        <DetailsContainer width={"50%"}>
          <FormColumn alignItems="stretch" height="70%" setJustify={true}>
            <InlineText
              fontSize="lg"
              font="medium"
              color="black"
              ref={el}
              data-tip
              data-for={cellData.Name}
              nowrap={"true"}
            >
              {getHighlightedText(cellData.Name, search)}
            </InlineText>
            <Tooltip el={el} value={cellData.Name} textColor={"black"} />
            <InlineText fontSize="xs">
              {getHighlightedText(cellData.Type, search)}
            </InlineText>
            <InlineText fontSize="xs">
              {getHighlightedText(cellData.SerialNumber, search)}
            </InlineText>
            <InlineText fontSize="xs">
              {getHighlightedText(cellData.Owner, search)}
            </InlineText>
            <InlineText fontSize="xs">
              {getHighlightedText(location, search)}
            </InlineText>
          </FormColumn>
          <Row height={"30%"} width="100%" shadow="0" alignItems="end">
            <Pill
              widthvw="4.58"
              fontColor={cellData.ConnectionStatus ? "white" : "black"}
              color={status.color}
              nowrap={true}
              ref={statusRef}
              minWidth={"fit-content"}
              data-tip
              data-for={cellData.Name + "pill"}
            >
              {status.text}
            </Pill>
            <Tooltip
              el={statusRef}
              id={cellData.Name + "pill"}
              value={status.text}
              textColor={"black"}
            />
            <Spacing setPointer={true} mLeft={"auto"} mTop={"auto"}>
              {auth.user.userType === "Admin" && (
                <IconHover
                  mTop="auto"
                  mBottom="auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    showEditCellPopUp(true, cellData.CellID);
                  }}
                >
                  <EditIcon color="black" strokeWidth="0.8" width={20} />
                </IconHover>
              )}
            </Spacing>
          </Row>
        </DetailsContainer>
      </ShadowDiv>
    </Spacing>
  );
};

export default CellCard;
