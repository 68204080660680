import React, { useState, useRef, useEffect } from "react";
import SiteHeader from "../../Header";
import { useHistory } from "react-router-dom";
import * as Routes from "../../routes/Routes";
import * as Topics from "../../AWS/IoTTopics";
import { useTranslation } from "react-i18next";
import LeftColumn from "./leftColumn/LeftColumn";
import MiddleColumn from "./middleColumn/MiddleColumn";
import RightColumn from "./rightColumn/RightColumn";
import { useParams } from "react-router-dom";
import { SessionManager, NetworkController } from "../../utils";
import {
  RightAlignedContainer,
  PageHeading,
  Loader,
  Spacing,
  Pill,
  PageBody,
  InlineText,
  Row,
} from "../../commonalities";
import CNMUsageCards from "./topHeader/CNMUsageCards";
import EvaloUsageCards from "./topHeader/EvaloUsageCards";
import CarpenUsageCards from "./topHeader/CarpenUsageCards";
import { getCells } from "../../utils";

const CellDetail = () => {
  //ERROR, OK, WARNING, NOT_CONNECTED, NO_WATER, DOOR_OPEN, DEFRROSTING, TOOL_ERROR, E_STOPPED
  const colors = [
    "red",
    "green",
    "orange",
    "grey",
    "red",
    "red",
    "orange",
    "red",
    "red",
  ];
  const params = useParams();
  const [search, setSearch] = useState("");
  const history = useHistory();
  const { t } = useTranslation();
  const childEventListener = useRef(null);
  const [cellDetails, setCellDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState(
    SessionManager.cells && SessionManager.cells[params.CellID]
      ? SessionManager.cellsShadows[params.CellID].location
      : null
  );
  const [settings, setSettings] = useState(
    SessionManager.cells && SessionManager.cells[params.CellID]
      ? SessionManager.cellsShadows[params.CellID].settings
      : null
  );
  const [config, setConfig] = useState(null);
  const [softwareVersions, setSoftwareVersions] = useState(null);
  const [status, setCellStatus] = useState(
    SessionManager.cells && SessionManager.cells[params.CellID]
      ? SessionManager.cellsShadows[params.CellID].status
      : null
  );
  const [hardwareStatus, setHardwareStatus] = useState(
    SessionManager.cells && SessionManager.cells[params.CellID]
      ? SessionManager.cellsShadows[params.CellID].hardwareStatus
      : null
  );
  const setEventListener = (listener) => {
    childEventListener.current = listener;
  };
  const IOTEventListener = (cellID, topic, data) => {
    if (Topics.PROCESS_COMPLETED_TOPIC === topic && params.CellID === cellID) {
      setCellDetails({ ...SessionManager.cells[cellID] });
      childEventListener.current(data);
    }
    if (cellID === params.CellID) {
      if (topic === Topics.CONNECTED_TOPIC_SUB) {
        setCellDetails({ ...SessionManager.cells[cellID] });
      } else if (topic === Topics.STATUS_UPDATE_TOPIC_ACCEPTED) {
        setCellStatus({ ...SessionManager.cellsShadows[cellID].status });
      } else if (topic === Topics.SETTINGS_UPDATE_TOPIC_ACCEPTED) {
        setSettings({ ...SessionManager.cellsShadows[cellID].settings });
      } else if (topic === Topics.CNM_STATUS_UPDATE_TOPIC_ACCEPTED) {
        setHardwareStatus({
          ...SessionManager.cellsShadows[cellID].hardwareStatus,
        });
      } else if (topic === Topics.HARDWARE_STATUS_UPDATE_TOPIC_ACCEPTED) {
        setHardwareStatus({
          ...SessionManager.cellsShadows[cellID].hardwareStatus,
        });
      } else if (topic === Topics.LOCATION_UPDATE_TOPIC_ACCEPTED) {
        setLocation(SessionManager.cellsShadows[cellID].location);
      } else if (topic === Topics.SOFTWARE_VERSION_UPDATE_TOPIC_ACCEPTED) {
        setSoftwareVersions({
          ...SessionManager.cellsShadows[cellID].softwareVersions,
        });
      } else if (topic === Topics.CONFIG_UPDATE_TOPIC_ACCEPTED) {
        setConfig({ ...SessionManager.cellsShadows[cellID].config });
      }
    }
  };
  useEffect(() => {
    if (SessionManager.cells && SessionManager.cells[params.CellID]) {
      SessionManager.cells[params.CellID].Usage.DailyUsage = null;
    }
    getCells(() => {
      if (
        SessionManager.cells === null ||
        SessionManager.cells[params.CellID] === undefined
      ) {
        history.replace(Routes.PAGE_NOT_FOUND);
      }
      if (SessionManager.cells[params.CellID].Type === "CNM") {
        NetworkController.cnm.getUsage(params.CellID, (res) => {
          var details = { ...SessionManager.cells[params.CellID] };
          details.Usage.DailyUsage = {};
          details.Usage.DailyUsage.Cuts = res.data.dailyCuts;
          details.Usage.DailyUsage.Parts = res.data.dailyParts;
          details.Usage.DailyUsage.CutLength = res.data.dailyCutLength;
          setCellDetails(details);
        });
      } else if (SessionManager.cells[params.CellID].Type === "Carpen") {
        NetworkController.carpen.getUsage(params.CellID, (res) => {
          var details = { ...SessionManager.cells[params.CellID] };
          details.Usage.DailyUsage = {};
          details.Usage.DailyUsage.Cuts = res.data.dailyCuts;
          details.Usage.DailyUsage.Parts = res.data.dailyParts;
          details.Usage.DailyUsage.CutLength = res.data.dailyCutLength;
          setCellDetails(details);
        });
      } else {
        NetworkController.evalo.getUsage(params.CellID, (res) => {
          var details = { ...SessionManager.cells[params.CellID] };
          details.Usage.DailyUsage = {};
          details.Usage.DailyUsage.TotalArea = res.data.dailyTotalArea;
          details.Usage.DailyUsage.PanelCount = res.data.dailyPanelCount;
          details.Usage.DailyUsage.WorkHrsSaved = res.data.dailyWorkHrsSaved;
          setCellDetails(details);
        });
      }
      setLoading(false);
      setSoftwareVersions({
        ...SessionManager.cellsShadows[params.CellID].softwareVersions,
      });
      setCellStatus(SessionManager.cellsShadows[params.CellID].status);
      setLocation(SessionManager.cellsShadows[params.CellID].location);
      setHardwareStatus(
        SessionManager.cellsShadows[params.CellID].hardwareStatus
      );
      setSettings(SessionManager.cellsShadows[params.CellID].settings);
      setConfig(SessionManager.cellsShadows[params.CellID].config);
      setCellDetails({ ...SessionManager.cells[params.CellID] });
      SessionManager.IOTEventListener = IOTEventListener;
    });
    return () => {
      SessionManager.IOTEventListener = null;
    };
  }, []);

  return (
    <>
      <SiteHeader search={search} setSearch={setSearch} />
      {loading && <Loader />}
      {cellDetails !== null && (
        <PageBody width="3.33">
          <Spacing setInlineFlex={true} width="100%" height="60px">
            <PageHeading
              heading={cellDetails.Name}
              redirection={Routes.CONTROL_ROOM}
            />
            <RightAlignedContainer height={"100%"} alignSelf="center">
              <Spacing mTtop="1.5%">
                <InlineText color="black" setInline={true} fontSize="sm">
                  {t("status")}
                </InlineText>
              </Spacing>
              <Spacing setInline={true} mLeftvw={"1.67"} />
              <Pill
                padding={"8px 24px"}
                fontColor={
                  cellDetails.ConnectionStatus
                    ? cellDetails.Type === "CNM"
                      ? status.programStatusCode !== 3
                        ? "white"
                        : "black"
                      : "white"
                    : "black"
                }
                color={
                  cellDetails.ConnectionStatus
                    ? cellDetails.Type === "CNM"
                      ? colors[status.programStatusCode]
                      : "green"
                    : "grey"
                }
                nowrap={true}
              >
                {cellDetails.ConnectionStatus
                  ? cellDetails.Type === "CNM"
                    ? t(status.programStatus) + " " + status.programStatusEnd
                    : t("cell-online")
                  : t("cell-offline")}
              </Pill>
            </RightAlignedContainer>
          </Spacing>
        </PageBody>
      )}
      <PageBody width="6.66" mTop="0" mLeft="auto" mRight="auto">
        {cellDetails !== null && (
          <>
            <Spacing height="fit-content">
              <Row
                bgColor="backgroundGrey"
                gap="1.67"
                width="100%"
                shadow="0"
                setJustify={true}
                heightvh="23.7"
              >
                {cellDetails.Type === "CNM" ? <CNMUsageCards cellDetails={cellDetails} />
                  : (cellDetails.Type === "Evalo" ? <EvaloUsageCards cellDetails={cellDetails} />
                    : <CarpenUsageCards cellDetails={cellDetails} />)}
              </Row>
              <Row
                bgColor="backgroundGrey"
                gap="1.67"
                width="100%"
                shadow="0"
                setJustify={true}
                height={"fit-content"}
              >
                <LeftColumn
                  versionInfo={softwareVersions}
                  settings={settings}
                  config={config}
                  setCellDetails={setCellDetails}
                  location={location}
                  cellDetails={cellDetails}
                  isOnline={cellDetails.ConnectionStatus}
                />
                <MiddleColumn
                  cellType={cellDetails.Type}
                  setEventListener={setEventListener}
                  setLoading={setLoading}
                  cellId={params.CellID}
                />
                <RightColumn
                  cellType={cellDetails.Type}
                  hardwareStatus={hardwareStatus}
                  cellId={params.CellID}
                  isOnline={cellDetails.ConnectionStatus}
                />
              </Row>
            </Spacing>
          </>
        )}
        <Spacing mTopvw="1.67" />
      </PageBody>
    </>
  );
};

export default CellDetail;
