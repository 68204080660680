import { SessionManager } from "../../../utils";

const getBarBackground = (canvas, inputData) => {
  const ctx = canvas.getContext("2d");
  var data = inputData.ycoords;
  var colors = inputData.style.borderColor;
  var col = [];
  const graphHeight = parseFloat(canvas.style.height) - 20;
  colors.forEach((color, index) => {
    var start = color.replace(/\d+\s*\)$/g, 0.3 + ")");
    var end = color.replace(/\d+\s*\)$/g, 1 + ")");
    const startheight = (Math.abs(data[index][1] - 6) / 12) * graphHeight + 20;
    const endheight = (Math.abs(data[index][0] - 6) / 12) * graphHeight + 20;
    console.log("canvas.style.height" + canvas.style.height);
    let gradientStroke = ctx.createLinearGradient(0, startheight, 0, endheight);
    gradientStroke.addColorStop(0, end);
    gradientStroke.addColorStop(1, start);
    col.push(gradientStroke);
  });
  return col;
};

//calculating the online time of every cell according to the filter and formating it so that it can be used by the graph to display the data.
export const getData = (filter) => {
  let cellOnlineStats = {};

  const currentTime = new Date();
  const weekStartDay = new Date(
    currentTime.getTime() - 6 * 24 * 60 * 60 * 1000
  );

  const currentWeekStart = new Date(
    weekStartDay.getFullYear(),
    weekStartDay.getMonth(),
    weekStartDay.getDate()
  );

  if (SessionManager.cells) {
    Object.values(SessionManager.cells).forEach((cell) => {
      if (cell.Usage && cell.Usage.OnlineStats) {
        const cellsUsage = [];
        let usedDays = 0;
        cell.Usage.OnlineStats.forEach((day) => {
          if (
            day.lastSession &&
            (day.lastSession.offline || day.totalSessions)
          ) {
            if (day.lastSession.online > currentWeekStart.getTime()) {
              usedDays++;
              let daysAverageStartTime =
                day.totalStartTime / Math.max(1, day.totalSessions);
              let daysAverageEndTime =
                day.totalEndTime / Math.max(1, day.totalSessions);
              let StartTime = new Date(daysAverageStartTime);
              let EndTime = new Date(daysAverageEndTime);
              cellsUsage.push([StartTime, EndTime]);
            } else {
              cellsUsage.push([0, 0]);
            }
          }
        });
        let weeklyTotalUsage = 0;
        let weeksTotalStartTime = 0;
        cellsUsage.forEach((dayUsage) => {
          if (dayUsage[1] !== 0) {
            weeklyTotalUsage +=
              (dayUsage[1].getTime() - dayUsage[0].getTime()) / 60000;
            weeksTotalStartTime +=
              dayUsage[0].getHours() * 60 + dayUsage[0].getMinutes();
          }
        });
        let weeklyStartTime = 0;
        let weeklyEndTime = 0;
        if (weeklyTotalUsage !== 0) {
          let weeklyAverageUsage = weeklyTotalUsage / (usedDays * 60);
          weeklyStartTime = weeksTotalStartTime / (usedDays * 60);
          weeklyEndTime = weeklyStartTime + weeklyAverageUsage;
        }
        //12 noon means 0 in the chart am is negitive pm is positive.
        let chartDataFormat = [];
        chartDataFormat.push((weeklyStartTime - 12).toFixed(2));
        chartDataFormat.push((weeklyEndTime - 12).toFixed(2));

        if (weeklyStartTime !== 0) {
          cellOnlineStats[cell.CellID] = chartDataFormat;
        }
      }
    });
  }

  let filteredCells;
  let dataset = [];

  //Cells active in last 24hrs
  if (filter === "active") {
    let ts24HrAgo = new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000
    ).getTime();

    filteredCells = Object.keys(cellOnlineStats)
      .filter((cellId) => {
        let onlineStats = SessionManager.cells[cellId].Usage.OnlineStats;
        if (onlineStats) {
          if (onlineStats[6].lastSession.online > ts24HrAgo) {
            return true;
          } else {
            return false;
          }
        }
      })
      .splice(0, 10);
  }
  //5 most active cells
  else if (filter === "mostUsed") {
    filteredCells = Object.keys(cellOnlineStats)
      .sort((a, b) => {
        if (
          cellOnlineStats[a][0] - cellOnlineStats[a][1] >
          cellOnlineStats[b][0] - cellOnlineStats[b][1]
        ) {
          return 1;
        } else {
          return -1;
        }
      })
      .splice(0, 5);
  }
  //5 least active cells
  else if (filter === "leastUsed") {
    filteredCells = Object.keys(cellOnlineStats)
      .sort((a, b) => {
        if (
          cellOnlineStats[a][0] - cellOnlineStats[a][1] <
          cellOnlineStats[b][0] - cellOnlineStats[b][1]
        ) {
          return 1;
        } else {
          return -1;
        }
      })
      .splice(0, 5);
  }

  // If atleast one cell exists in filteredCells
  if (filteredCells[0]) {
    const cellOnlineAverageDataset = {
      xcoords: [],
      ycoords: [],
      name: "CellOnlineAverage",
      style: {
        barThickness: 12,
        //backgroundColor: getBarBackground, For now, commenting it out as linear gradient for the bar is giving some trouble.
        borderColor: [],
        borderRadius: Number.MAX_VALUE,
        borderSkipped: false,
      },
    };
    filteredCells.forEach((cellId) => {
      const color = `rgba(${SessionManager.cells[cellId].Color.r},${SessionManager.cells[cellId].Color.g},${SessionManager.cells[cellId].Color.b},${SessionManager.cells[cellId].Color.a})`;
      cellOnlineAverageDataset.xcoords.push(SessionManager.cells[cellId].Name);
      cellOnlineAverageDataset.ycoords.push(cellOnlineStats[cellId]);
      cellOnlineAverageDataset.style.borderColor.push(color);
    });
    dataset.push(cellOnlineAverageDataset);
  }

  return dataset;
};
