import React, { useState } from "react";
import { NetworkController } from "../../../utils";
import { useEffect } from "react";
import {
  Spacing,
  PopUpHeading,
  PopUpText,
  PopUpTemplate,
  TextButton,
  Button,
} from "../../../commonalities";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";

const PlayBagFilePopUp = ({ setShowPopUp, job }) => {
  const { t } = useTranslation();
  const [stageMessage, setStageMessage] = useState({
    message1: "",
    message2: "",
    stage: "",
  });

  const handlePlay = () => {
    NetworkController.cells.playExecutionBag({}, (res) => {
      if (res.data === 1) {
        setStageMessage({
          message1: t("cell-playingBagMessage"),
          message2: "",
          stage: "completed",
        });
      }
    });
  };

  const exitPopUp = () => {
    setTimeout(() => {
      setShowPopUp(false);
    }, 70);
  };

  useEffect(() => {
    setStageMessage({
      message1: t("cell-bagPlanningSceneMessage1"),
      message2: t("cell-bagPlanningSceneMessage2"),
      stage: "setupScene",
    });

    NetworkController.cells.getExecutionData(
      { id: job.Payload.Id },
      async (res) => {
        if (res.data.Count === 0) {
          setStageMessage({
            message1: t("cell-bagFileUnavailable"),
            message2: "",
            stage: "completed",
          });
        } else {
          const settingsData = await new Promise((resolve, reject) => {
            NetworkController.cells.getSettingsData(
              { id: job.Payload.Id },
              (response) => {
                resolve(response);
              }
            );
          });
          var config = settingsData.data.config.Items[0].Payload.Config;
          if (typeof config === "string") {
            config = JSON.parse(config);
          }
          res.data.Items[0].Payload["ToolName"] =
            config.tools.tools[0].tool_name;
          res.data.Items[0].Payload["SubToolName"] =
            config.tools.tools[0].sub_tools[0].sub_tool_name;

          NetworkController.cells.sendExecutionDataToSetupPlanningScene(
            res.data,
            (res) => {
              if (res.data === 1) {
                setStageMessage({
                  message1: t("cell-bagPlayMessage1"),
                  message2: t("cell-bagPlayMessage2"),
                  stage: "playScene",
                });
              } else {
                setStageMessage({
                  message1: t("cell-bagServerError"),
                  message2: "",
                  stage: "completed",
                });
              }
            }
          );
        }
      }
    );
  }, []);

  return (
    <PopUpTemplate setShowPopUp={setShowPopUp} height="fit-content">
      <Spacing paddingvh={"6.67"} pRightvw={"6.25"} pLeftvw={"6.25"}>
        <PopUpHeading>{t("cell-jobReplay")}</PopUpHeading>
        {stageMessage.stage === "setupScene" && (
          <Spacing
            mTopvh={"3.7"}
            setFitContent={true}
            mLeft={"auto"}
            mRight={"auto"}
          >
            <CircularProgress />
          </Spacing>
        )}
        <Spacing mTopvh={"1.1"} />
        {stageMessage.message1 !== "" && (
          <Spacing mTopvh={"2.96"}>
            <PopUpText color={"textGrey"}>{stageMessage.message1}</PopUpText>
          </Spacing>
        )}

        {stageMessage.message2 !== "" && (
          <Spacing
            mTopvh={"1.1"}
            width="max-content"
            mLeft={"auto"}
            mRight={"auto"}
          >
            <PopUpText setInline={true} color={"textGrey"}>
              {stageMessage.message2}
            </PopUpText>
          </Spacing>
        )}
        {stageMessage.stage === "setupScene" && (
          <Spacing
            width="max-content"
            mTopvh={"2.96"}
            mLeft={"auto"}
            mRight={"auto"}
          >
            <Button onClick={exitPopUp} fontSize={"md"}>
              {t("buttons-cancel")}
            </Button>
          </Spacing>
        )}
        {stageMessage.stage === "completed" && (
          <Spacing
            width="max-content"
            mTopvh={"2.96"}
            mLeft={"auto"}
            mRight={"auto"}
          >
            <Button onClick={exitPopUp} fontSize={"md"}>
              {t("buttons-okay")}
            </Button>
          </Spacing>
        )}
        {stageMessage.stage === "playScene" && (
          <>
            <Spacing
              width="max-content"
              mTopvh={"2.96"}
              mLeft={"auto"}
              mRight={"auto"}
            >
              <Button fontSize={"md"} onClick={handlePlay}>
                {t("buttons-play")}
              </Button>
            </Spacing>
            <Spacing
              width="max-content"
              mTopvh={"0.74"}
              mLeft={"auto"}
              mRight={"auto"}
            >
              <TextButton onClick={exitPopUp}>{t("buttons-cancel")}</TextButton>
            </Spacing>
          </>
        )}
        <Spacing mBottom={"xl"} />
      </Spacing>
    </PopUpTemplate>
  );
};

export default PlayBagFilePopUp;
